import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class ToastifyService {
  constructor(private _toastService: ToastrService) {}

  info(message: string, title: string = 'Info') {
    this._toastService.info(message, title);
  }

  success(message: string, title: string = 'Success') {
    this._toastService.success(message, title);
  }

  error(message: string, title: string = 'Failed') {
    this._toastService.error(message, title);
  }

  warning(message: string, title: string = 'Warning', timeOut: number = 5000) {
    this._toastService.warning(message, title, { timeOut });
  }

  clear() {
    this._toastService.clear();
  }
}
