import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  BehaviorSubject,
  catchError,
  Observable,
  of,
  shareReplay,
  Subject,
  tap,
} from 'rxjs';
import { HttpService } from '../../../app/services/http.service';
import { environment } from '../../../environments/environment';
import { UserModel } from '../Models/user.model';
import { ToastifyService } from '../../../app/services/toast.service';
import { AdminTemplateService } from '../../../app/services/admin.template.service';

const API_USERS_URL = `${environment.API_USERS_URL}`;
const API_URL = `${environment.apiURL}`;

export type UserType = UserModel | undefined;

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  currentUser$ = new BehaviorSubject<UserType>(undefined);
  isLoadingSubject$ = new BehaviorSubject<boolean>(false);

  isLoading$: Observable<boolean>;
  siteKey: any;
  captchaLoaded: boolean;

  constructor(
    private router: Router,
    private http: HttpClient,
    private httpService: HttpService,
    private toast: ToastifyService,
    private template: AdminTemplateService
  ) {
    this.isLoading$ = this.isLoadingSubject$.asObservable();
  }

  get currentUserValue(): UserType {
    return this.currentUser$.value;
  }

  set currentUserValue(user: UserType) {
    this.currentUser$.next(user);
  }

  login(email: string, password: string): Observable<UserType> {
    this.isLoadingSubject$.next(true);
    var body =
      'username=' + email + '&password=' + password + '&grant_type=password';

    //  var body =
    //   'client_id=admin#client_id&client_secret=admin#client_secret&grant_type=client_credentials';

    return this.http.post<UserModel>(`${API_USERS_URL}`, body).pipe(
      tap((user: UserType) => {
        this.currentUser$.next(user);
        const result = this.setAuthFromLocalStorage(user);
        return result;
      }),
      catchError((err) => {
        console.error('err', err);
        this.isLoadingSubject$.next(false);

        this.registerUser(email, password);

        return of(undefined);
      })
    );
  }

  logout() {
    localStorage.removeItem('user');
    localStorage.removeItem('merchantData');
    this.currentUser$.next(undefined);
    this.router.navigate(['/auth/login']);
  }

  getMerchantData(user: UserType) {
    this.isLoadingSubject$.next(true);
    return this.httpService.getRequest(`Admin/get/${user?.userName}`).pipe(
      tap((res) => {
        if (res.Info.Status == 200) {
          this.template.setTemplate(res.Data);
        }
      }),
      shareReplay()
    );
  }

  async getUserByToken(): Promise<UserType | undefined> {
    const user = await this.getAuthFromLocalStorage();

    if (!user || !user.access_token) {
      return Promise.resolve(undefined);
    }

    this.currentUserValue = user;
    return Promise.resolve(user);
  }

  getCaptchaSiteKey() {
    if (this.captchaLoaded) {
      this.isLoadingSubject$.next(false);
      return;
    }
    this.isLoadingSubject$.next(true);

    this.http.get(`${API_URL}ReCaptcha/getSiteKey/AdminPortal`).subscribe({
      next: (res: any) => {
        if (res.Info.Status == 200) {
          this.siteKey = res.SiteKey;
          const captcha = window.document.createElement('script');
          captcha.id = 'grecaptcha';
          captcha.type = 'text/javascript';
          captcha.src = `https://www.google.com/recaptcha/api.js?render=${res.SiteKey}`;
          window.document.body.appendChild(captcha);
          captcha.onload = () => {
            this.isLoadingSubject$.next(false);
            this.captchaLoaded = true;
          };
          return;
        }
        this.isLoadingSubject$.next(false);
      },
      error: (error) => {
        this.isLoadingSubject$.next(false);
      },
    });
  }

  verifyCaptcha(token: string): Observable<any> {
    this.isLoadingSubject$.next(true);

    const result$ = new Subject<boolean>();
    if (!this.siteKey) return of(true);

    const body = {
      Response: token,
      Type: 'AdminPortal',
    };

    this.http.post(`${API_URL}ReCaptcha/getSiteResponse`, body).subscribe({
      next: (res: any) => {
        if (res.Info.Status == 200) {
          result$.next(true);
          return;
        }
        this.toast.error(res.Info.Message);
      },
      error: (error) => {
        this.toast.error(
          error.message || 'Something went wrong could not verify CAPTCHA'
        );
        this.isLoadingSubject$.next(false);
      },
    });

    return result$;
  }

  loginUser(email: string, password: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http
        .get(`${API_URL}Merchant/checkMerchantLogin/${email}/${password}`)
        .subscribe({
          next: (res: any) => {
            if (res) {
              // Assuming 'Success' is the success message
              resolve(true);
            } else {
              this.toast.error('Incorrect Credentials');
              resolve(false);
            }
          },
          error: (error) => {
            this.hideSpinner();
            this.toast.error(
              error.message || 'Something went wrong could not register user'
            );
            resolve(false); // Resolve false in case of an error
          },
          complete: () => {
            this.hideSpinner();
          },
        });
    });
  }

  registerUser(email: string, password: string) {
    const body = {
      Email: email,
      Password: password,
      BrandId: 300,
    };

    this.http.post(`${API_URL}Account/Register`, body).subscribe({
      next: (res: any) => {
        if (res.Info.Status == 200) {
          this.login(email, password);
          return;
        }
        this.toast.error(res.Info.Message);
      },
      error: (error) => {
        this.toast.error(
          error.message || 'Something went wrong could not register user'
        );
        this.isLoadingSubject$.next(false);
      },
    });
  }

  showSpinner() {
    this.isLoadingSubject$.next(true);
  }

  hideSpinner() {
    this.isLoadingSubject$.next(false);
  }

  private getAuthFromLocalStorage(): Promise<UserModel | undefined> {
    return new Promise((resolve, reject) => {
      try {
        let user: any = localStorage.getItem('user');

        if (!user) {
          return resolve(undefined);
        }

        const authData = JSON.parse(user);

        const expirydate = new Date(authData['.expires']);

        if (new Date() > expirydate) {
          return resolve(undefined);
        }

        this.getMerchantData(authData).subscribe({
          next: (res) => {
            if (res.Info.Status == 200) {
              localStorage.setItem('merchantData', JSON.stringify(res['Data']));
              resolve(authData);
              return;
            }
            alert(res.Info.Message);
            location.reload();
            this.logout();
          },
          error: (e) => {
            alert(
              e.message ||
                'Could not load merchant data please check your internet connection and Refresh page'
            );
            this.logout();
          },
        });
      } catch (error) {
        console.error(error);
        return reject(undefined);
      }
    });
  }

  private setAuthFromLocalStorage(user: UserType): boolean {
    localStorage.setItem('user', JSON.stringify(user));
    return true;
  }
}
