import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  baseUrl: any;
  constructor(private httpClient: HttpClient) {}

  getRequest(url: string): Observable<any> {
    const _user = localStorage.getItem('user');
    let token = '';
    if (_user) {
      const user = JSON.parse(_user);
      token = user.access_token;
    }

    const httpHeaders = new HttpHeaders({
      Authorization: 'bearer ' + token,
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    });

    return this.httpClient.get(environment.apiURL + url, {
      headers: httpHeaders,
    });
  }

  postRequest(url: string, body: any): Observable<any> {
    const _user = localStorage.getItem('user');
    let token = '';
    if (_user) {
      const user = JSON.parse(_user);
      token = user.access_token;
    }
    const httpHeaders = new HttpHeaders({
      Authorization: 'bearer ' + token,
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    });
    return this.httpClient.post(environment.apiURL + url, body, {
      headers: httpHeaders,
    });
  }

  putRequest(url: string, body: {}): Observable<any> {
    const _user = localStorage.getItem('user');
    let token = '';
    if (_user) {
      const user = JSON.parse(_user);
      token = user.access_token;
    }
    const httpHeaders = new HttpHeaders({
      Authorization: 'bearer ' + token,
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    });
    return this.httpClient.put(environment.apiURL + url, body, {
      headers: httpHeaders,
    });
  }

  deleteRequest(url: string): Observable<any> {
    const _user = localStorage.getItem('user');
    let token = '';
    if (_user) {
      const user = JSON.parse(_user);
      token = user.access_token;
    }
    const httpHeaders = new HttpHeaders({
      Authorization: 'bearer ' + token,
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    });
    return this.httpClient.delete(environment.apiURL + url, {
      headers: httpHeaders,
    });
  }

  postImage(url: string, data: any) {
    const _user = localStorage.getItem('user');
    let token = '';
    if (_user) {
      const user = JSON.parse(_user);
      token = user.access_token;
    }

    const httpHeaders = new HttpHeaders({
      Authorization: 'bearer ' + token,
    });

    return this.httpClient.post(environment.apiURL + url, data, {
      headers: httpHeaders,
    });
  }
}
