import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AdminTemplate } from '../Models/Template';

@Injectable({
  providedIn: 'root',
})
export class AdminTemplateService {
  private templateSubject = new BehaviorSubject<AdminTemplate | undefined>(
    undefined
  );

  template$ = this.templateSubject.asObservable();

  setTemplate(temp: AdminTemplate) {
    this.templateSubject.next(temp);
  }
}
